@use "../../../../styles/variables" as var;

.dots-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40.2px;
}

.dot {
  width: 14px;
  height: 14px;
  border: 1px solid var.$color-main;
  display: inline-block;
  transform: rotate(45deg);

  animation-name: animation;
  animation-duration: 2s;
  animation-iteration-count: infinite;

  &:nth-child(2) {
    animation-delay: 0.2s;
  }

  &:nth-child(3) {
    animation-delay: 0.4s;
  }
}

@keyframes animation {
  35% {
    background-color: transparent;
  }

  50% {
    background-color: var.$color-main;
  }

  65% {
    background-color: transparent;
  }
}
