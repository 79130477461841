@use "../../../styles/variables.scss" as var;

.news-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 36px;
  row-gap: 36px;
}

.news-box {
  @media (max-width: var.$mobile-max) {
    margin: 0 12px 0;
  }
}

.news-title {
  font-size: 24px;
  font-weight: bold;
  line-height: 1.4;
  margin-top: 20px;
  text-align: center;
  @media (max-width: 1400px) {
    font-size: 1.6vw;
  }
  @media (max-width: var.$mobile-max) {
    margin-top: 12px;
    font-size: 20px;
  }
  @media (max-width: 500px) {
    font-size: 5vw;
  }
}

.news-date {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.4;
  margin-top: 16px;
  text-align: right;
  @media (max-width: 1400px) {
    font-size: 1.6vw;
  }
  @media (max-width: var.$mobile-max) {
    font-size: 16px;
  }
}

.news-module {
  position: relative;
  max-width: 500px;
  margin: 0 auto;

  .news-prev {
    position: absolute;
    top: 40%;
    left: 64px;
    width: 30.15px;
    transform: translateY(-50%);
    z-index: 2;
    transition: 0.1s linear;

    img {
      width: 100%;
    }

    &:hover {
      left: 56px;
    }
  }

  .news-next {
    position: absolute;
    top: 40%;
    right: 64px;
    width: 30.15px;
    transform: translateY(-50%);
    z-index: 2;
    transition: 0.1s linear;

    img {
      width: 100%;
    }

    &:hover {
      right: 56px;
    }
  }
}

.swiper-pagination-clickable {
  .swiper-pagination-bullet {
    display: none;
  }
}

.sp-news-wrapper {
  position: relative;
  width: 343px;
  margin: 0 auto;

  .upper-news-frame {
    display: flex;
    justify-content: space-between;
    margin-bottom: -38px;
  }

  .lower-news-frame {
    display: flex;
    justify-content: space-between;
    margin-top: -38px;
  }
}

.news-card {
  width: 100%;
  height: 397px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 418px;
  border: 1px solid var.$color-main;
  overflow: hidden;
  transition: 0.1s linear;
  cursor: pointer;

  img {
    transition: 0.1s linear;
  }

  .news-card-text-area {
    background: #141414;
    overflow-wrap: break-word;
    padding: 24px;
    flex: 1;
    transition: 0.1s linear;
  }

  &:hover {
    color: var.$color-main;

    img {
      transform: scale(1.1);
    }

    .news-card-text-area {
      background: rgba(1, 183, 223, 0.2);
    }
  }

  @media (max-width: var.$mobile-max) {
    width: 309px;
    height: 100%;
    max-width: auto;
    align-items: center;
    margin: 0 auto;

    .news-card-text-area {
      width: 309px;
      min-height: 96px;
      padding: 16px;
    }

    &:hover {
      img {
        transform: none;
      }
    }
  }
}
