@use "../../../../styles/variables" as var;

.item {
  position: relative;
  width: 80%;
  height: 80%;
  margin-top: 3rem;
  margin: 0 auto;
}

.item::before {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.item-text {
  font-size: 28px;
  margin-top: 20px;
  text-align: center;
  color: #fff;

  @media (max-width: var.$mobile-max) {
    font-size: 20px;
  }

  @media (max-width: 500px) {
    font-size: 16px;
    margin-top: 12px;
  }
}
