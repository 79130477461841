@use "../styles/variables" as var;

.background {
  background-image: url("../public/images/bg-top-pc.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: -1;
  @media (max-width: var.$mobile-max) {
    background-image: url("../public/images/bg-top-sp.jpg");
  }
}

.main {
  width: 100%;
  background-color: #000;
}

.section-wrapper {
  width: 100%;
  padding: 96px 0 0 0;
  position: relative;
  height: 100%;
  margin-top: -6rem;

  @media (max-width: var.$mobile-max) {
    padding: 56px 0 0 0;
  }

  &.section-bottom {
    padding: 60px 0 40px 0;
  }

  .inner-about {
    padding: 14vw 10vw 10vw 48vw;
    background-image: url("/images/lp/n-three-sided-view.jpg");
    background-repeat: no-repeat;
    background-size: 66vw auto;
    background-position: left top;
    @media (max-width: 1500px) {
      padding: 14vw 2vw 16vw 43vw;
      background-size: 80vw auto;
      background-position: left -15vw top;
    }
    @media (max-width: var.$mobile-max) {
      padding: 80px 16px 40px 16px;
      background-size: 588px auto;
      background-position: left 90px top;
    }
  }

  .inner-world {
    text-align: center;
    padding: 4vw 2vw 8vw 2vw;
  }

  .inner-ain {
    padding: 4vw 2vw 0vw 20vw;
    @media (max-width: 1500px) {
      padding: 2vw 2vw 0vw 12vw;
    }
    @media (max-width: var.$mobile-max) {
      padding: 20px 16px 0 16px;
    }
  }

  .inner-gallery {
    .section-title {
      text-align: center;
      margin-bottom: 40px;
    }

    @media (max-width: var.$mobile-max) {
      padding-top: 32px;
      .section-title {
        margin-bottom: 20px;
      }
    }
  }

  .inner-utility {
    text-align: center;
    padding: 0vw 2vw 5vw 2vw;
    width: 80vw;
    max-width: 1250px;
    margin: 4vw auto 4vw;
    position: relative;

    @media (max-width: var.$mobile-max) {
      padding: 0vw 3vw 5vw 3vw;
      width: 90vw;
      .section-content {
        .content-title {
          margin-top: 16px;
          line-height: 1.5;
        }
      }
    }

    &::before {
      position: absolute;
      top: 20px;
      left: -5%;
      content: "";
      background-image: url("/images/utility-left.png");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: left top;
      width: 40px;
      height: 100%;
      @media (max-width: var.$mobile-max) {
        background-image: url("/images/utility-left-sp.png");
        top: 60px;
        left: -3%;
        height: calc(100% - 60px);
      }
    }
    &::after {
      position: absolute;
      top: 20px;
      right: -5%;
      content: "";
      background-image: url("/images/utility-right.png");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: right top;
      width: 40px;
      height: 100%;
      @media (max-width: var.$mobile-max) {
        background-image: url("/images/utility-right-sp.png");
        top: 60px;
        right: -3%;
        height: calc(100% - 60px);
      }
    }
  }

  .inner-community {
    text-align: center;
    padding: 8vw 2vw 0 2vw;
    @media (max-width: var.$mobile-max) {
      padding: 8vw 4vw 0 4vw;
    }
  }

  .inner-news {
    text-align: center;
    padding: 4vw 2vw 0 2vw;
    max-width: 1350px;
    margin: 0 auto;
    @media (max-width: var.$mobile-max) {
      padding: 4vw 3vw 0 3vw;
    }
    .section-content {
      margin-top: 40px;
      @media (max-width: var.$mobile-max) {
        margin-top: 24px;
      }
    }
  }
}

.section-title {
  font-size: 4vw;
  line-height: 1.1;
  font-weight: bold;
  letter-spacing: 0.05em;
  color: #fff;
  text-shadow: 2px 2px 0 #000, 2px -2px 0 #000, -2px 2px 0 #000,
    -2px -2px 0 #000;
  @media (max-width: 1500px) {
    font-size: 50px;
  }
}

.section-content {
  .content-title {
    font-size: 40px;
    line-height: 1.7;
    font-weight: bold;
    color: #fff;
    margin-top: 32px;
    text-shadow: 1px 1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
      -1px -1px 0 #000;
    @media (max-width: 1500px) {
      font-size: 24px;
    }
    @media (max-width: var.$mobile-max) {
      font-size: 20px;
      font-weight: bold;
    }
  }
  .content-text {
    font-size: 16px;
    line-height: 1.5;
    color: #fff;
    margin-top: 20px;
    text-shadow: 1px 1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
      -1px -1px 0 #000;
    @media (max-width: var.$mobile-max) {
      font-size: 14px;
      font-weight: bold;
    }
  }
}

.world-ain-wrap {
  position: relative;
  overflow: hidden;
  @media (max-width: var.$mobile-max) {
    padding-bottom: 150px;
  }
  .world-ain-image {
    position: absolute;
    bottom: 0;
    right: -5vw;
    img {
      width: 800px;
      opacity: 0.5;
    }
    @media (max-width: 1500px) {
      img {
        width: 680px;
      }
    }
    @media (max-width: var.$mobile-max) {
      right: 0;
      img {
        width: 60vw;
      }
    }
  }
}

.diamond-list {
  @media (max-width: var.$mobile-max) {
    margin-top: 0;
  }
  &.is-gallery {
    @media (max-width: var.$mobile-max) {
      position: absolute;
      top: -90px;
      left: 50%;
      margin-left: -9px;
      margin-top: 0;
    }
  }
  li {
    margin-bottom: 40px;
  }
  img {
    width: 18px;
    margin: 0 auto;
    @media (max-width: var.$mobile-max) {
      width: 14px;
    }
  }
}

.home-gallery {
  position: relative;
  padding: 102px 0 48px;
  border-top: 2px solid var.$color-main;
  border-bottom: 2px solid var.$color-main;
  background: #000;
  opacity: 0.8;

  @media (max-width: var.$mobile-max) {
    padding: 20px 0 12px;
  }

  .home-gallery-image {
    position: absolute;
    top: 0;
  }
}

.gallery-more-button {
  text-align: center;
  margin-top: 40px;
  @media (max-width: var.$mobile-max) {
    margin-top: 24px;
  }
}

.bottom-logo-wrapper {
  width: 580px;
  margin: 60px auto 20px;
  img {
    width: 100%;
  }
  @media (max-width: 640px) {
    width: 90%;
    margin: 20px auto 20px;
  }
}

.news-more-button {
  text-align: center;
  margin-top: 40px;
  @media (max-width: var.$mobile-max) {
    margin-top: 16px;
  }
}

.no-news {
  font-size: 24px;
  font-weight: bold;
}

.contents {
  font-size: 0.8vw;
}

.wrapper {
  margin: 0 12vw;
}

.updates-list-wrapper {
  border: 4px solid #00a783;
  height: 22vw;
  overflow: scroll;
}

.scrollbar::-webkit-scrollbar {
  width: 36px;
  height: 0px;
}

.scrollbar-thumb::-webkit-scrollbar-thumb {
  border-radius: 14.5px;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  background-clip: padding-box;
  background-color: #00a783;
}

.play-btn-block {
  margin-top: 30px;
}

.play-btn-anchor {
  display: inline-block;
  border: 2px solid #00a783;
  background: rgba(0, 167, 131, 0.5);
  color: #fff;
  font-size: 35px;
  font-weight: bold;
  padding: 0 24px;
  letter-spacing: 2px;
}

.play-btn-anchor:hover {
  opacity: 0.8;
}

@media screen and (max-width: 767px) {
  .play-btn-anchor {
    font-size: 24px;
  }
}

$about-padding-top: 210px;
$about-padding-bottom: 130px;

.about-wrapper {
  position: relative;
  padding-top: $about-padding-top;
  padding-bottom: $about-padding-bottom;
  overflow-x: hidden;

  @media (max-width: var.$mobile-max) {
    min-height: 1108px;
  }
}

.video-top-black-rect {
  position: absolute;
  top: 0;
  width: 100%;
  height: $about-padding-top;
  background: #000;
}

.video-background {
  position: relative;
  overflow: hidden;
  z-index: -1;

  width: 100%;
  height: 100%;

  > .pr-video {
    width: 100%;
    height: 100%;
  }

  @media (max-width: var.$mobile-max) {
    height: 768px;

    > .pr-video {
      object-fit: cover;
    }
  }
}

.gradient-rect {
  position: absolute;
  z-index: 0;
  height: 240px;
  width: 100vw;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    #000 22.4%,
    #000 77.08%,
    rgba(0, 0, 0, 0) 100%
  );

  @media (max-width: var.$mobile-max) {
    height: 180px;
  }
}

.about-title {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: -1;
  max-width: 100%;
  width: 100%;
  height: 100%;
}

.slider__wrapper {
  position: absolute;
  top: $about-padding-top;
  left: 50%;
  transform: translateX(-50%);

  .slide-contents-wrapper {
    max-width: 1221px;
    text-align: center;

    h3 {
      font-weight: bold;
      font-size: 48px;
      line-height: 67.2px;
      background-color: rgba(0, 0, 0, 0.72);
      margin-bottom: 32px;
      letter-spacing: normal;
    }

    .slide-contents-p-wrapper {
      padding: 85px 0;
      background-color: rgba(0, 0, 0, 0.72);

      &.no-padding {
        padding: 0;
      }

      p {
        font-weight: bold;
        font-size: 24px;
        line-height: 42px;
        letter-spacing: normal;
      }
    }
  }

  @media (max-width: 1220px) {
    max-width: 1000px;

    .slide-contents-wrapper {
      h3 {
        font-size: 36px;
        line-height: 50.4px;
      }

      .slide-contents-p-wrapper {
        p {
          font-size: 16px;
          line-height: 28px;
        }
      }
    }
  }

  @media (max-width: var.$mobile-max) {
    max-width: 311px;

    .slide-contents-wrapper {
      h3 {
        font-size: 20px;
        line-height: 28px;
      }

      .slide-contents-p-wrapper {
        padding: 0;

        p {
          font-size: 14px;
          line-height: 24.5px;
        }
      }
    }
  }
}

.swiper-button-prev {
  width: 80px;
  height: 159px;
  position: absolute;
  top: 45%;
  left: -100px;
  transition: 0.1s linear;

  &:hover {
    left: -110px;
  }

  @media (max-width: var.$mobile-max) {
    width: 30.15px;
    height: 60px;
    left: -16px;

    &:hover {
      left: -24px;
    }
  }
}

.swiper-button-next {
  width: 80px;
  height: 159px;
  position: absolute;
  top: 45%;
  right: -120px;
  transition: 0.1s linear;

  &:hover {
    right: -130px;
  }

  @media (max-width: var.$mobile-max) {
    width: 30.15px;
    height: 60px;
    right: -36px;

    &:hover {
      right: -44px;
    }
  }
}

.swiper-button-next::after {
  visibility: hidden;
}

.swiper-button-prev::after {
  visibility: hidden;
}

.about-swiper-pagination {
  position: absolute;
}

.nft-utility-heading-wrapper {
  margin-top: 34px;
  position: relative;

  .nft-utility-heading {
    max-width: 679px;
    position: absolute;
    top: 4px;
    left: 32px;
    color: #000;
    font-size: 24px;
    line-height: 28.8px;
    font-weight: bold;
    letter-spacing: 6%;

    &.is-japanese {
      line-height: 24px;
      top: 21px;
    }
  }

  @media (max-width: 1300px) {
    .nft-utility-heading {
      top: 2px;
      font-size: 16px;
      line-height: 20px;

      &.is-japanese {
        top: 12px;
        left: 20px;
      }
    }
  }

  @media (max-width: var.$mobile-max) {
    margin-top: 0;
    text-align: center;

    .nft-utility-heading {
      width: 100%;
      max-width: 343px;
      top: 8px;
      font-size: 20px;
      line-height: 24px;
      left: 50%;
      transform: translateX(-50%);

      &.is-japanese {
        left: 50%;
        top: 8px;
      }
    }
  }
}

.nft-utility-contents {
  margin-top: 32px;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  text-shadow: 0px 6px 10px rgba(0, 0, 0, 0.14),
    0px 1px 18px rgba(0, 0, 0, 0.12), 0px 3px 5px rgba(0, 0, 0, 0.2);

  @media (max-width: var.$mobile-max) {
    font-size: 14px;
    line-height: 24.5px;
    max-width: 319px;
    margin: 0 auto;
    text-align: center;
  }
}

.gallery-section-top-border {
  height: 2px;
  width: 1200px;
  background-color: var.$color-main;

  @media (max-width: var.$mobile-max) {
    width: 200px;
  }
}
